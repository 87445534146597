
.education-wrapper {
  height: 100%;
  /deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-scrollbar__view {
      padding: 60px;
    }
  }
}
.create-content {
  margin: 60px 0;
}
.bottom-btn {
  text-align: center;
}
.horizontal-form-item {
  display: flex;
  /deep/ .el-form-item__content {
    margin-left: 20px;
  }
}

.first-title {
  font-size: 18px;
  margin-bottom: 22px;
}
.success-content {
  margin-top: 155px;
  text-align: center;
  .iconfont {
    color: #1fd18c;
    font-size: 52px;
  }
  .text {
    font-size: 24px;
    margin: 20px 0 0;
    font-weight: bold;
  }
}
